body {
  font-family: 'Helvetica', sans-serif;
  background-color: #1d1d1d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.app1 {
  background-color: '#1d1d1d';
}

.responsive-padding {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 150px;

  @media (max-width: 1391px) {
    padding-right: 15px;
  }
}
